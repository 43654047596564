<template>
  <b-card
    class="stocklog-edit-wrapper"
  >
    <!-- form -->
    <b-form id="stocklogForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="企业ID"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="stocklog.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="企业名称"
            label-for="company_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_name"
              size="sm"
              v-model="stocklog.company_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="stocklog.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="stocklog.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="stocklog.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存编号"
            label-for="stock_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_no"
              size="sm"
              v-model="stocklog.stock_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="类型   stock_log_type"
            label-for="log_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="log_type"
              size="sm"
              v-model="stocklog.log_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="变更成本"
            label-for="log_cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="log_cost"
              size="sm"
              v-model="stocklog.log_cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="变更前数量"
            label-for="source_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="source_qty"
              size="sm"
              v-model="stocklog.source_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="变更数量 纯数字"
            label-for="log_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="log_qty"
              size="sm"
              v-model="stocklog.log_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="变更数量 带符号 +进库 -出库"
            label-for="log_qty_sign"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="log_qty_sign"
              size="sm"
              v-model="stocklog.log_qty_sign"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="变更后数量"
            label-for="after_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="after_qty"
              size="sm"
              v-model="stocklog.after_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 instock_status"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="stocklog.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="stocklog.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="stocklog.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="SKU"
            label-for="sku"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sku"
              size="sm"
              v-model="stocklog.sku"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="图片"
            label-for="image"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="image"
              size="sm"
              v-model="stocklog.image"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="成本"
            label-for="cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost"
              size="sm"
              v-model="stocklog.cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="stocklog.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="stocklog.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="stocklog.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="stocklog.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="remark"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remark"
              size="sm"
              v-model="stocklog.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="stocklog.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="stocklog.creator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import stocklogStore from './stocklogStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      stocklog: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stocklog')) store.registerModule('stocklog', stocklogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stocklog')) store.unregisterModule('stocklog')
    })

    const edit = function() {
      store.dispatch('stocklog/edit', {id: this.id}).then(res => {
        this.stocklog = res.data.data
      })
    }

    const view = function() {
      store.dispatch('stocklog/view', {id: this.id}).then(res => {
        this.stocklog = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('stocklog/save', this.stocklog).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-stocklog-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
